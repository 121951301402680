@media (max-width: 1600px) {
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper .nav-tabs .nav-link {
    padding: 0.5rem 6.5px !important;
  }
  .files_description-tables-wrapper,
  .files_description-tables-wrapper span {
    font-size: 15px;
  }
  .fixed_table.statements_table td,
  .fixed_table.statements_table th {
    font-size: 16px !important;
  }
}

@media (min-width: 1200px) {
  .statement-modal.bills_page .modal-content {
    width: calc(100vw - 290px) !important;
  }
  .statement-modal.bills_page .modal-dialog {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}
@media (min-width: 1400px) {
  .driver_tab_left_side {
    margin-top: 65px;
  }
  .expenses_left_side {
    margin-top: 0 !important;
  }
}

@media (max-width: 1400px) {
  .header_absolute-wrapper {
    margin-top: 43px;
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper:not(.custom_margin)
    .tab-content {
    margin-top: 40px;
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper.custom_margin
    .tab-content {
    margin-top: 100px;
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper.height-auto {
    height: auto;
  }
}

@media (max-width: 1200px) {
  .filter_toggle_statement {
    max-width: 100px;
  }
  .chat-section {
    height: calc(92vh - 25vh);

    .loads-sidebar-wrapper {
      // flex-direction: row;

      .driver-info {
        position: absolute;
        padding: 15px 20px;
        margin-bottom: 15px;
        z-index: 1;

        h5 {
          font-size: 14px;
          margin-bottom: 0;
        }
      }

      .chat-search-wrap {
        margin: 12px 20px 10px 80px;
      }
    }

    .chat-screen-wrapper {
      .chat-header {
        flex-wrap: wrap;
        padding: 15px 20px;

        &:after {
          display: none;
        }

        .left {
          width: 100%;
          justify-content: space-between;

          .load-num {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 3px;
            padding: 8px;
          }
          .group-members button {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0;
          }
        }

        .right {
          margin-top: 10px;
          width: 100%;
          flex: 1;
        }
      }

      .chat-footer {
        padding: 5px 20px 10px 20px;

        &:before {
          display: none;
        }
      }
    }
  }
  .second_select_truck {
    padding-left: 4px;
    padding-right: 0px;
  }
  .edit_data_bordered-wrapper footer .submit_button-edit {
    margin-right: 10px !important;
  }
  .fade.show .edit_data_bordered-wrapper footer,
  .edit_driver_wrapper footer,
  .fade.show .edit_driver_wrapper footer {
    padding: 0 0 20px 10px !important;
    display: flex;
    justify-content: center;
    button {
      width: 130px !important;
    }
  }
  .edit_data_bordered-wrapper.bg-none-opacity {
    padding: 0 !important;
    margin-right: 15px !important;
  }
  .second_button_data_edit_add-wrapper {
    button {
      font-size: 12px !important;
    }
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper .nav-tabs .nav-link {
    padding: 0.5rem 0px;
    font-size: 14px !important;
  }
}

@media (min-width: 992px) {
  .c-app.c-default-layout.sidebar-set-on-hover .c-sidebar:not(:hover) {
    transform: translate(-160px, 0);
  }
  .c-app.c-default-layout.sidebar-set-on-hover
    .c-sidebar:not(:hover)
    .c-sidebar-brand {
    position: fixed;
    margin-top: -75px;
  }
  .c-app.c-default-layout.sidebar-set-on-hover .c-sidebar {
    transition: all 0s ease;
    background-color: white;
    box-shadow: 1px 2px 5px 0.5px black !important;
  }

  html:not([dir="rtl"])
    .c-app.c-default-layout.sidebar-set-on-hover
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 50px !important;
  }

  html:not([dir="rtl"])
    .c-app.c-default-layout.sidebar-set-on-hover
    .main_content_wrapper {
    width: calc(100% - 50px);
  }

  html:not([dir="rtl"])
    .c-app.c-default-layout.sidebar-set-on-hover
    header:not(.edit_data-header_info) {
    width: calc(100% - 45px);
    left: 40px !important;
  }
  .footer_version {
    position: fixed;
    bottom: 15px;
    left: 15px;
    color: black;
    z-index: 100;
    font-weight: 700;
    font-style: italic;
    font-variant-caps: small-caps;
  }
}

@media (max-width: 992px) {
  .second_select_truck {
    padding-left: 12px;
    padding-right: 12px;
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper .nav-tabs .nav-link {
    padding: 0.5rem 13px !important;
  }

  .md-label-centre {
    .form-group div {
      justify-content: center;
      display: flex !important;
    }
    label {
      display: flex;
      justify-content: center;
      margin-left: auto !important;
    }
  }
  .expenses_right_side {
    margin-top: 20px;
  }
  .load_number_header {
    font-size: 10px;
    padding: 6px !important;
  }
  .fixed_table .custom-table_pagination .pagination {
    height: 27px;
  }
  .fixed_table .custom-table_pagination {
    top: 76px;
    right: 0;
  }
  .fixed_table .remove_filters_button {
    top: 56px !important;
    left: 0 !important;
  }
  .registerapprove header {
    min-height: 55px;
  }
  .loads_page header .row {
    justify-content: right;
  }
  .loads_page header .row .col-6:nth-child(3) {
    margin-top: 3px;
  }
  .loads_page .custom-table_pagination {
    top: 95px !important;
  }
  .c-body.main_content_wrapper.invoices .c-main .table-mt-40 {
    margin-top: 120px !important;
  }
  .c-body.main_content_wrapper.invoices .c-main .space_around-wrapper {
    position: absolute;
    top: 48px;
  }
  .c-body.main_content_wrapper.invoices .c-main .space_around-wrapper button {
    font-size: 10px;
    padding: 2px 5px !important;
    height: 25px;
    width: 180px;
  }
  .edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper),
  .edit_driver_wrapper {
    min-height: calc(100vh - 58px) !important;
    height: auto !important;
  }
  .html:not([dir="rtl"]) .c-switch-label .c-switch-slider::after {
    left: -66px !important;
  }
  .button_switch-wrapper.isSold .c-switch-label .c-switch-slider::after {
    left: -66px !important;
  }
  .drivers_activities_card .prices_summ_loads_page-wrapper .download_excell {
    margin-top: 7px !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 768px) {
  .c-chat-layout {
    height: 90vh;
    min-height: auto;
    overflow-y: hidden;

    .c-wrapper {
      min-height: auto;
    }

    .c-header {
      height: 112px;
    }

    .c-body {
      max-height: calc(90vh - 115px);
    }

    .c-main {
      padding-top: 1rem;
    }
  }

  .chat-section {
    height: calc(90vh - 20vh);

    .loads-sidebar-wrapper {
      .driver-info {
        padding: 10px 10px 15px 10px;

        h5 {
          font-size: 14px;
        }
      }
      .loads {
        font-size: 12px;
        .load .badges {
          .unread-badge {
            font-size: 10px;
          }
          .time {
            font-size: 11px;
          }
        }
      }
    }

    .contact-sidebar-wrapper {
      .logo-heading {
        img {
          width: 40px;
          height: 40px;
        }

        .user-name {
          font-size: 14px;
          .title {
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
      .contact-list {
        ul {
          .contact-element {
            padding: 10px 20px;
            img {
              width: 35px;
              height: 35px;
            }
            .user {
              font-size: 12px;
            }
            .unread-badge {
              font-size: 10px;
            }
          }
        }
      }
    }

    .chat-screen-wrapper {
      .chat-header {
        padding: 12px;
      }
      .chat-body {
        padding: 10px;
        font-size: 12px;

        .message {
          margin: 7px 0;
          max-width: 220px;

          .audio-preview {
            max-width: 220px;
          }
          &.sender,
          &.receiver {
            &::after {
              display: none;
            }
          }
        }
      }
      .chat-footer {
        padding: 5px 12px;
        background-color: #f8f8f8;

        form {
          .file-upload {
            top: 36%;
          }
          .send-icon {
            top: 12%;
          }
        }
        .search {
          margin-top: 5px;
        }
        .scroll-to-bottom {
          bottom: 75px;
          right: 12px;
          width: 30px;
          height: 30px;
          font-size: 15px;
          line-height: 30px;
        }
      }
    }
    .go-back-btn {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      font-size: 14px;
    }
    .search {
      padding: 8px 8px 8px 40px;
      font-size: 12px;
    }
  }
  form .form-group {
    label {
      text-align: center !important;
      display: flex;
      justify-content: center;
      margin: 0 auto 24.9px auto;
    }
  }
  .fade.show .edit_data_bordered-wrapper footer,
  .edit_driver_wrapper footer {
    display: flex;
    justify-content: center;
    button {
      width: 130px !important;
    }
  }
  .edit_data_bordered-wrapper .edit_data-header_info,
  .edit_data_bordered-wrapper .info_header,
  .edit_driver_wrapper .edit_data-header_info,
  .edit_driver_wrapper .info_header {
    width: 100% !important;
    height: auto !important;
    border-bottom-right-radius: 0 !important;
  }
  .second_button_data_edit_add-wrapper {
    display: flex;
    button {
      margin: auto;
    }
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper .nav-tabs .nav-link {
    padding: 0.5rem 11.4px !important;
  }
  .sm-mt-30 {
    margin-top: 30px !important;
  }
  .load_number_header {
    display: none;
  }
  .micro_icons-wrapper {
    margin-bottom: 20px;
  }
  .rate-radio-wrapper label:nth-of-type(1)::after {
    transform: translate(5px, 0) !important;
  }
  .rate-radio-wrapper label:nth-of-type(2)::after {
    transform: translate(5px, 0) !important;
  }
  .rate-radio-wrapper label:nth-of-type(3)::after {
    transform: translate(6px, 0) !important;
  }
  .statements .custom-table_pagination {
    top: 130px;
  }
  .input_with_formatted_value-wrapper span.desription_input {
    bottom: -17px;
  }
  .rate_confirmation_generate_button {
    top: 0 !important;
    left: 25%;
  }
  .rate_confirmation_generate_button button {
    padding: 1px !important;
  }
}

@media (max-width: 576px) {
  .fade.show .edit_data_bordered-wrapper footer,
  .edit_driver_wrapper footer {
    button {
      width: 70px !important;
      padding-right: 2px;
      padding-left: 2px;
      margin-right: 5px !important;
      font-size: 15px;
    }
    img {
      width: 14px !important;
      font-size: 15px !important;
    }
  }
  .edit_data_bordered-wrapper.tabs_exist .card-body.sm-pt-80 {
    padding-top: 80px !important;
  }
  .sm-mt-20 {
    margin-top: 20px !important;
  }
  .header_absolute-wrapper {
    margin-top: 100px;
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper:not(.custom_margin)
    .tab-content {
    margin-top: 100px;
  }
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper.custom_margin
    .tab-content {
    margin-top: 157px;
  }
  .fixed_table .remove_filters_button {
    font-size: 10px;
  }
  .fixed_table header button {
    font-size: 10px;
  }
  .edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper)
    .submit_button-edit,
  .edit_driver_wrapper .submit_button-edit {
    font-size: 12px !important;
  }
  .edit_driver_wrapper header button {
    width: 100px;
    transform: translate(-40px, 0);
  }
  .breadcrumb_header-wrapper ol a,
  .breadcrumb_header-wrapper ol li:before,
  .breadcrumb_header-wrapper ol li.breadcrumb-item {
    margin-top: auto;
  }
}

@media (max-width: 476px) {
  .c-switch-label .c-switch-input:checked ~ .c-switch-slider::after {
    left: -60px !important;
    font-size: 14px !important;
  }
  html:not([dir="rtl"]) .c-switch-label .c-switch-slider::after {
    left: -93px !important;
    font-size: 14px !important;
  }
  .header-top_part .header_link-dashboard {
    font-size: 17px !important;
  }
  .c-subheader.breadcrumb_header-wrapper {
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 20px;
    .breadcrumb_header.breadcrumb {
      display: contents;
    }
  }
  .rate_confirmation_generate_button {
    top: -25px !important;
    left: inherit !important;
    right: calc(100% - 225px) !important;
  }
}

@media (max-width: 420px) {
  .border-radius_wrapper.tabs-bordered.load_edit_wrapper .nav-tabs .nav-link {
    padding: 0.5rem 2px !important;
    font-size: 14px;
  }
  .edit_driver_wrapper:not(.one_more_fields) .card .driver_files_header select {
    min-width: 150px !important;
  }
}

@media screen and (min-width: 2560px) {
  .chat-section {
    height: calc(100vh - 135px);
  }
}
