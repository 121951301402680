.modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 80%;
  width: fit-content;
  height: 80%;
  background-color: transparent;
  border: none;
  border-radius: none;

  .btn-close {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    padding: 35px;
    background-color: transparent;
    font-size: 35px;
    transition: all 200ms ease;

    svg path {
      stroke: #fff;
    }

    &:hover {
      transition: all 200ms ease;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
