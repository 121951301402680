// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

@font-face {
  font-family: MyriadPro;
  src: url("../fonts/MyriadPro/MYRIADPRO-REGULAR.OTF") format("opentype");
}

@font-face {
  font-family: MyriadConceptRoman;
  src: url("../fonts/MyriadRoman/MyriadRoman.otf") format("opentype");
}

@font-face {
  font-family: GoodTimesRg;
  src: url("../fonts/GoodTimesRg/good_times_rg.ttf") format("truetype");
}

@font-face {
  font-family: Barlow;
  src: url("../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Barlow-Bold;
  src: url("../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}
