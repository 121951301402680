// dispatchDrivers Page
.driver_dispatch.row .table td {
  vertical-align: middle;
  .driver_dispatch-inline-block {
    display: inline-block;
    &.truck_id-span {
      width: 9ch;
    }
    &.phone-span {
      cursor: pointer;
      width: max-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: darkblue;
      &:hover {
        text-decoration: underline;
      }
    }
    &.pick_up-span,
    &.delivery-span {
      width: max-content;
    }
    &.notes-span {
      width: max-content;
    }
  }

  .select_wrapper {
    display: flex;
    align-items: center;
  }
  .select_td {
    position: relative;
    padding: 3px !important;
  }

  ul.select_status.driver_dispatch {
    border: 0px;
    margin: auto;
    position: relative;
    box-sizing: content-box;
    width: 100px;
    height: 10px;
    padding: 4px;
    &:hover svg {
      transform: scale(1.1);
    }
    .main_li {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
    }

    ul:hover > li {
      margin: 0;
    }
    ul li:hover {
      background-color: hsl(0, 0%, 84%) !important;
      transition: all 0.1s linear;
    }
    li.main_li:nth-child(1) {
      color: black;
      border: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0;
    }
    li {
      color: black;
    }
    li.bg-primary {
      color: white !important;
    }
    li.bg-HasNextLoad {
      background-color: #b1d0e0 !important;
    }
    li.bg-Dispatched {
      background-color: #f9b115 !important;
    }
    li.bg-Ready1 {
      background-color: #2eb85c !important;
      color: white !important;
    }
    li.bg-Ready2 {
      background-color: #e55353 !important;
      color: white !important;
    }
    li.bg-Ready3 {
      background-color: #636f83 !important;
      color: white !important;
    }
    li.bg-Transit {
      background-color: #3399ff !important;
      color: white !important;
    }
    li.bg-Shop {
      background-color: #ced2d8 !important;
    }
    li.bg-Reset {
      background-color: #e55353 !important;
    }
    li.bg-Rest {
      background-color: #ebedef !important;
    }
    li.bg-Reserved {
      background-color: #321fdb !important;
      color: white !important;
    }
    li.bg-Home {
      background-color: #84dfff !important;
    }
    li.bg-Vacation {
      background-color: #519259 !important;
      color: white !important;
    }
  }
  ul.select_status.flex {
    li.bg-secondary:not(:nth-child(1)):hover {
      background-color: white !important;
      transition: background-color 0.1 ease;
    }
  }

  ul.select_status.driver_dispatch li {
    list-style: none;
    padding-bottom: 6px;
    padding-top: 6px;
    display: flex;
    justify-content: center;
    li:not(:nth-child(1)) {
      transform: translateX(0px);
    }
  }
  ul.select_status.driver_dispatch.flex {
    display: flex;
    flex-direction: column;
    overflow: inherit;
    max-width: 100px;
    list-style: none;
    ul {
      display: block;
      flex-direction: column;
      list-style: none;
      padding: 0;
      position: absolute;
      width: 95%;
      padding: 0;
      left: 0px;
      top: 18px;
      z-index: 9999;
    }
  }

  ul.select_status.driver_dispatch:not(.select-to_top) ul {
    box-shadow: 2px 4px 3px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 8px 1px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 8px 1px 0px rgba(0, 0, 0, 0.2);
    width: 100px;
    li {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  ul.select_status.driver_dispatch.flex.select-to_top {
    ul {
      top: -321px;
      width: 120px;
      left: 0px;
      display: flex;
      flex-direction: column-reverse;
      box-shadow: 5px 1px 5px 1px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.2);
      li {
        z-index: 9999;
        width: 110px !important;
      }
    }
  }

  ul.select_status.driver_dispatch.none {
    li:not(:nth-child(1)) {
      visibility: hidden;
    }
  }
  .select-main_status {
    text-align: center;
    width: 100%;
    padding-left: 4px;
  }

  ul.select_status.driver_dispatch span.image_border {
    width: 30px;
    display: flex;
    justify-content: center;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    &:hover svg {
      transform: scale(1.1);
    }
  }

  ul.select_status.driver_dispatch:not(.select-to_top) span.image_border.rotate,
  ul.select_status.driver_dispatch.select-to_top
    span.image_border:not(.rotate) {
    svg {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      transition-property: transform;
      transition-duration: 0.8s;
    }
  }

  ul.select_status.driver_dispatch:not(.select-to_top)
    span.image_border:not(.rotate),
  ul.select_status.driver_dispatch.select-to_top span.image_border.rotate {
    svg {
      transition-duration: 0.8s;
      transition-property: transform;
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
  }

  ul.select_status.driver_dispatch {
    cursor: pointer;
  }

  ul.select_status.driver_dispatch ul {
    overflow: inherit;
  }

  ul.select_status.driver_dispatch.flex ul {
    perspective: 500px;
    z-index: 100;
    li {
      cursor: pointer;
      width: 110px;
      animation: {
        name: menu1;
        duration: 300ms;
        timing-function: linear;
        fill-mode: forwards;
      }
    }
  }
}

// loads page

.loads_page {
  .table td {
    vertical-align: middle;
  }
  span.loads_inline-block {
    display: inline-block;
    &.shipper_consigne-span {
      width: max-content;
    }
  }
}

.users-inline-block.phone-span,
.users-inline-block.email-span {
  cursor: pointer;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: darkblue;
}

.users-inline-block.phone-span:hover,
.users-inline-block.email-span:hover {
  text-decoration: underline;
}

.dispatch_load-inline_block {
  display: inline-block;
  width: max-content;
}

.clusterMarker {
  background-image: url("https://unpkg.com/@googlemaps/markerclustererplus@1.0.3/images/m1.png");
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 47px;
  height: 47px;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 100;
}

.clusterMarker::after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  box-shadow: 0 0 1px 2px #1c3c64;
}

.clusterMarker_number {
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  margin: 0;
}

.google-map {
  position: relative;
}

.google-map span.badge {
  font-size: 1.5vh;
}

.custom-button_google-map {
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 0px;
  padding: 0px 17px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(0, 0, 0);
  font-size: 18px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  box-shadow: rgba(0, 0, 0, 30%) 0px 1px 4px;
  min-width: 35px;
  font-weight: 500;
  position: absolute;
  left: 8px;
  bottom: 5px;
  z-index: 999;
}

.dashboard div[role*="dialog"],
.map_tab-pane div[role*="dialog"] {
  background-color: #2eb85c !important;
  padding: 0 !important;
  max-width: 100px !important;
  max-height: 60px !important;
  opacity: 1;
}
.dashboard div[role*="dialog"] p,
.map_tab-pane div[role*="dialog"] p {
  margin: 0;
  padding: 0;
}
.dashboard div[role*="dialog"] .gm-style-iw-d,
.map_tab-pane div[role*="dialog"] .gm-style-iw-d {
  font-size: 1.5vh;
  line-height: 1;
  overflow: hidden !important;
  display: inline-flex;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  padding: 0px;
}

.dashboard div[role*="dialog"] button,
.map_tab-pane div[role*="dialog"] button {
  display: none !important;
}

.notHoveredDesription {
  display: none !important;
}

.gm-style .gm-style-iw-t::after {
  height: 0 !important;
}

.gm-style-iw-d .badge {
  margin: 0 !important;
  padding: 5px !important;
  white-space: normal;
}

.selectedMarker {
  z-index: 800;
}

.loader_wrapper-add__load {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 30%;
}

.tables-id_field {
  a,
  span {
    display: flex !important;
    flex-wrap: nowrap;
    width: max-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-customer_field {
  span {
    display: inline-flex;
    flex-wrap: nowrap;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-price_field {
  span {
    display: inline-flex;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-miles_field {
  span {
    display: inline-flex;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-date_fields {
  flex-wrap: nowrap;
}

.table-shipper-consigne_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-booked_by-dispatched_by_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-notes_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
  }
}

.tables-references_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.tables-phone_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.tables-trailer_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-truck_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-company_name_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-city_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-state_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-zip_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table-company_mc_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.table_broker-date_field {
  flex-wrap: nowrap;
  width: max-content;
}

.table-reference_field {
  span {
    display: inline-flex;
    height: 1.6vmax;
    line-height: 1.6vmax;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-modal-preview.image-formatter {
  img {
    max-width: 100% !important;
  }
}

.wrapper_download {
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: relative;
  .img_text_wrapper-downloader {
    display: contents;
  }
  img {
    width: 100%;
  }
  .text_on_image_wrapper {
    display: inline-flex;
    justify-content: right;
    vertical-align: bottom;
    height: 100%;
    width: 100%;
    text-align: right;
    vertical-align: bottom;
    .text_on_image {
      font-size: 14px;
      font-weight: 500;
      color: #fbfaf9;
      background: rgba(0, 0, 0, 0.15);
      width: 100%;
      padding-right: 14px;
      font-style: italic;
      position: absolute;
      left: 0;
      bottom: 0;
      margin-left: 60%;
      width: 40%;
    }
  }
}

.download_wrapper-buttons {
  display: flex;
  justify-content: space-around !important;
  background-color: none !important;
  padding: 25px;
}

.text-info-download_modal {
  padding-top: 10px;
  text-align: center;
  font-weight: 600;
}

.td_header_download-wrapper {
  text-align: center;
}

.drivers_dispatch_first_driver-span {
  font-weight: 700;
}

.load_google_map {
  padding: 5px 10px;
}

.locationValidation_text {
  color: red !important;
}

tr {
  vertical-align: baseline !important;
}

.driver_dispatch-opened_td {
  padding-bottom: 240px !important;
  vertical-align: baseline !important;
}

.notes_load_edit:not(pre) {
  height: 150px !important;
}

td.no-wrap {
  white-space: nowrap;
}

.timeFilter_span {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  svg {
    margin: auto 5px auto 3px !important;
    padding-left: 5px !important;
    width: 10px;
  }
}

.timeFilter_span {
  ::-webkit-calendar-picker-indicator {
    margin-left: -20px;
  }
}

.table-date_fields {
  white-space: nowrap !important;
}
// @media only screen and (min-height:800px) {

.fixed_table thead {
  overflow-x: scroll;
  margin-top: -33px;
}

.form-inline.c-datatable-filter {
  margin-top: -8px;
}

.table-responsive thead tr {
  padding: 0;
  margin: 0;
}

.table-responsive thead {
  border-collapse: collapse;
  background-color: #fff;
  border-spacing: 0;
}

.fixed_table {
  overflow-y: auto;
  height: 100px;
  border: 0;
  margin-left: -1px;
}

.table-responsive thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-responsive tbody td {
  overflow: hidden;
}
.table-responsive tbody th {
  position: sticky;
  left: 0;
}

.table-responsive {
  table {
    width: 100%;
  }
  tr,
  th {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  // th, td { padding: 8px 16px; }
  th {
    background: #fff;
  }
}

.datepicker_opened .table-responsive {
  min-height: 390px;
}

body,
html {
  height: 100%;
}
.fixed_table {
  height: max-content;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #6588ff;
}
// }

.fixed_table {
  &:not(.pagination_none) .table-responsive {
    // height: 500px;
    height: calc(100vh - 127px);
  }
  &.pagination_none .table-responsive {
    height: calc(100vh - 127px);
  }
}

.footer_display_none {
  display: none;
}

.table-filter_datepicker,
.react-datepicker__input-container {
  width: 91px;
}

.fixed_table .react-datepicker-wrapper {
  display: inline-block !important;
  position: relative;
  width: 91px !important;
  input {
    padding-left: 8px !important;
  }
  .react-datepicker__input-container {
    display: block !important;
  }
  .react-datepicker__tab-loop {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.micro_icons-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 40px !important;
  svg {
    width: 35px !important;
    height: 35px !important;
    display: flex !important;
    margin-left: 25px !important;
    cursor: pointer;
    perspective: 40px;
    &:not(.active_svg-micro):hover path {
      fill: blue;
    }
    &.active_svg-micro {
    }
  }
  &.hovered {
    margin-top: 20px;
    svg {
      display: flex !important;
      margin-left: 25px !important;
    }
  }
}

.rate-radio-wrapper {
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: rgba(0, 0, 16, 0.8);
    text-decoration: none;
  }

  a:hover,
  a:active {
    border-bottom: 0.1em solid rgba(0, 0, 16, 0.8);
    color: rgba(0, 0, 16, 0.8);
    text-decoration: none;
  }

  label::after {
    content: attr(data-value);
    position: absolute;
    bottom: 8px;
    font-size: 14px;
    color: black;
  }
  label:nth-of-type(1)::after {
    transform: translate(-37px, 0);
  }
  label:nth-of-type(2)::after {
    transform: translate(-35px, 0);
  }
  label:nth-of-type(3)::after {
    transform: translate(-40px, 0);
  }

  .rating {
    padding-bottom: 18px;
    border-radius: 2.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  span {
    color: rgba(0, 0, 16, 0.4);
    font-size: 70%;
  }

  header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    width: 34.6rem;
  }

  header h1 {
    font-size: 2.8em;
  }

  .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 16, 0.19),
      0 0.3rem 0.3rem rgba(0, 0, 16, 0.23);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 16, 0.19),
      0 0.3rem 0.3rem rgba(0, 0, 16, 0.23);
    background-color: rgb(255, 255, 255);
    padding: 0.8rem;
    width: 100%;
  }

  .rating-form {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .rating-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.4rem 0.8rem;
    width: 100%;
  }

  .rating-text p {
    color: rgba(0, 0, 16, 0.8);
    font-size: 1.3rem;
    padding: 0.3rem;
  }

  svg {
    fill: #000;
    height: 1.5rem;
    width: 1.5rem;
  }

  .rating-form-2 svg {
    height: 3rem;
    width: 3rem;
    margin: 0.5rem;
  }

  #radios label {
    position: relative;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  input[type="radio"] + svg {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  input + svg {
    cursor: pointer;
  }

  .rating-container:not(.bg-secondary) input[class="super-happy"]:hover + svg,
  .rating-container:not(.bg-secondary) input[class="super-happy"]:checked + svg,
  .rating-container:not(.bg-secondary) input[class="super-happy"]:focus + svg,
  .rating-container:not(.bg-secondary) input[class="super-happy active"] + svg {
    fill: green;
  }

  .rating-container:not(.bg-secondary) input[class="happy"]:hover + svg,
  .rating-container:not(.bg-secondary) input[class="happy"]:checked + svg,
  .rating-container:not(.bg-secondary) input[class="happy"]:focus + svg,
  .rating-container:not(.bg-secondary) input[class="happy active"] + svg {
    fill: rgb(0, 204, 79);
  }

  .rating-container:not(.bg-secondary) input[class="neutral"]:hover + svg,
  .rating-container:not(.bg-secondary) input[class="neutral"]:checked + svg,
  .rating-container:not(.bg-secondary) input[class="neutral"]:focus + svg,
  .rating-container:not(.bg-secondary) input[class="neutral active"] + svg {
    fill: rgb(232, 214, 0);
  }

  .rating-container:not(.bg-secondary) input[class="sad"]:hover + svg,
  .rating-container:not(.bg-secondary) input[class="sad"]:checked + svg,
  .rating-container:not(.bg-secondary) input[class="sad"]:focus + svg,
  .rating-container:not(.bg-secondary) input[class="sad active"] + svg {
    fill: rgb(229, 132, 0);
  }

  .rating-container:not(.bg-secondary) input[class="super-sad"]:hover + svg,
  .rating-container:not(.bg-secondary) input[class="super-sad"]:checked + svg,
  .rating-container:not(.bg-secondary) input[class="super-sad"]:focus + svg,
  .rating-container:not(.bg-secondary) input[class="super-sad active"] + svg {
    fill: rgb(239, 42, 16);
  }

  footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right;
    width: 34.6rem;
    margin: auto;
  }

  footer p {
    font-size: 1.3em;
  }

  @media screen and (max-width: 650px) and (max-height: 700px) {
    body,
    html {
      font-size: 0.7rem;
    }
    header h1 {
      font-size: 4em;
    }
    footer p {
      font-size: 2em;
    }
  }

  @media screen and (max-height: 700px) {
    body,
    html {
      font-size: 0.7rem;
    }
    header h1 {
      font-size: 4em;
    }
    footer p {
      font-size: 2em;
    }
  }

  @media screen and (max-width: 650px) {
    body,
    html {
      font-size: 0.7rem;
    }
    header h1 {
      font-size: 4em;
    }
    footer p {
      font-size: 2em;
    }
  }

  @media screen and (max-width: 450px) and (max-height: 550px) {
    body,
    html {
      font-size: 0.6rem;
    }
    header h1 {
      font-size: 4.6em;
    }
    footer p {
      font-size: 3em;
    }
  }

  @media screen and (max-height: 550px) {
    body,
    html {
      font-size: 0.6rem;
    }
    header h1 {
      font-size: 4.6em;
    }
    footer p {
      font-size: 3em;
    }
  }

  @media screen and (max-width: 450px) {
    body,
    html {
      font-size: 0.6rem;
    }
    header h1 {
      font-size: 4.6em;
    }
    footer p {
      font-size: 3em;
    }
  }

  @media screen and (max-width: 400px) and (max-height: 500px) {
    body,
    html {
      height: 500px;
      width: 400px;
    }
  }

  @media screen and (max-height: 500px) {
    body,
    html {
      height: 500px;
    }
  }

  @media screen and (max-width: 400px) {
    body,
    html {
      width: 400px;
    }
  }
}

.box {
  position: relative;
  svg {
    position: absolute;
    right: 4px;
    top: 10px;
  }
  &.record-box {
    .circle {
      background: #eaeaea;
    }
    .circle_ripple-2 {
      -webkit-animation-name: ripple 2s infinite;
      animation: ripple-2 2s infinite;
      background: #36b37e;
    }
  }
}

.circle_ripple-2 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: -5px;
}

@keyframes ripple-2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
  }
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  top: -10px;
}

.edit-load_switch {
  border: 0 !important;
  margin: auto !important;
}
.edit-load_switch span.c-switch-slider {
  width: 55px;
}
.c-switch-label .c-switch-input:checked ~ .c-switch-slider::after {
  left: -110px;
  top: 16px;
  color: #321fdb;
  position: absolute;
  white-space: nowrap;
  font-size: 23px;
}

html:not([dir="rtl"]) .c-switch-label .c-switch-slider::after {
  right: auto;
  left: -151px;
  top: 16px;
  white-space: nowrap;
  color: #321fdb;
  font-size: 23px;
}

.button_switch-wrapper {
  display: flex;
  justify-content: space-around;
  button {
    margin-right: 0;
    margin-left: auto;
  }
  label {
    margin-left: auto;
    margin-right: 0px;
  }
  .c-switch.c-switch-lg .c-switch-input:checked ~ .c-switch-slider::before {
    transform: translateX(23px) !important;
  }
}

.fixed_table {
  // &:not(.load_ids) table thead tr th:first-of-type {
  //   max-width: 3ch !important;
  // }
  thead tr:nth-child(2) th:nth-child(1) {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
  }
  thead tr th input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &.load_ids table thead tr th:first-of-type {
    max-width: 18ch !important;
  }
}
.fixed_table {
  table thead tr th,
  table tbody tr td {
    text-align: center;
  }
}

.fixed_table,
.tables-id_field {
  &:not(.load_ids) {
    table:not(.LocationsAsTable) tbody tr td:first-of-type {
      padding-left: 4px;
      padding-right: 4px;
      text-align: center !important;
      &.driver_loads_table_td {
        max-width: 18ch !important;
      }
      &:not(.load_ids) span,
      a,
      div {
        text-align: center !important;
        max-width: 7ch;
        margin: auto;
      }
    }
  }
}
.fixed_table.load_ids table tbody tr td:first-of-type {
  max-width: 16ch !important;
  span {
    max-width: 16ch !important;
  }
}

.c-header-fixed {
  margin-top: -2px !important;
}

.fixed_table.table_with_custom_statuses tbody tr td {
  padding: 4px 0.5rem;
}

.height_auto_loader_table {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.yearInputs-span-wrapper .react-datepicker__year-wrapper {
  justify-content: center;
}

.selects-react-datepicker .react-datepicker__year-select,
.selects-react-datepicker .react-datepicker__month-select {
  background: transparent !important;
  padding: 0 5px !important;
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
}

.companies-wrapper .card-body {
  height: calc(100vh - 175px);
}

select {
  option.bg-HasNextLoad {
    background-color: #b1d0e0 !important;
  }
  option.bg-Ready1 {
    background-color: #2eb85c !important;
    color: white !important;
  }
  option.bg-Ready2 {
    background-color: #e55353 !important;
    color: white !important;
  }
  option.bg-Ready3 {
    background-color: #636f83 !important;
    color: white !important;
  }
  option.bg-Transit {
    background-color: #3399ff !important;
    color: white !important;
  }
  option.bg-Shop {
    background-color: #ced2d8 !important;
  }
  option.bg-Reset {
    background-color: #e55353 !important;
  }
  option.bg-Reserved {
    background-color: #321fdb !important;
    color: white !important;
  }
  option.bg-Home {
    background-color: #84dfff !important;
  }
  option.bg-Vacation {
    background-color: #519259 !important;
    color: white !important;
  }
}
.load_notes_table_td {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.load_notes_table_td .load_notes_table_td-span {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 5px;
}

.driver_pin.selectedMarker .popover__content.info {
  bottom: -500px;
}

.odometer_values_table tbody tr td input,
.odometer_values_table tbody tr td input:hover,
.odometer_values_table tbody tr td input:focus,
.odometer_values_table tbody tr td input:focus-visible {
  max-width: 150px !important;
  padding: 2px 2px 2px 15px;
  box-shadow: 1px 2px 2px #888888;
  margin: 3.5px 0 !important;
  border: 0 !important;
  background: white !important;
  border-radius: 8px !important;
}
.odometer_values_table thead tr th,
.odometer_values_table tbody tr td {
  text-align: center;
  margin: auto;
}
.odometer_values_table .actions_wrapper {
  display: flex;
  justify-content: center;
}
.odometer_values_table .actions_wrapper button,
.odometer_values_table .actions_wrapper button:active,
.odometer_values_table .actions_wrapper button:focus,
.odometer_values_table .actions_wrapper button:focus-visible {
  padding: 0;
  border: 0 !important;
  background: transparent !important;
  outline: 0;
}

.odometer_values_table .actions_wrapper button img,
.odometer_values_table .actions_wrapper button:active img,
.odometer_values_table .actions_wrapper button:focus img {
  width: 30px;
  height: 30px;
  border: 0 !important;
  outline: 0;
  background: transparent !important;
}

.odometer_values_table .actions_wrapper button:nth-child(1) img {
  transform: rotate(180deg);
}

// .driver_pin {
//   width: 32px;
//   height: 32px;
// }
