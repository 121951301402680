@mixin unread-badge() {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #4c7dfe;
  color: #fff;
  width: 22px;
  height: 22px;
  margin-left: auto;
}

@mixin avatar-shadow() {
  border: 2px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@mixin no-user-drag() {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@mixin three-dots-text() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-chat-layout {
  overflow-x: hidden;
  .c-header.c-header-fixed {
    z-index: 1 !important;
  }
  .c-body {
    z-index: 1050;
    position: relative;
    right: -5px;
    width: calc(100.2%) !important;
    max-height: 770px;

    .c-main {
      height: auto;
    }
    .c-main > .container-fluid {
      padding: 0;
    }
  }
}

.chat-section {
  height: calc(100vh - 65px);
  margin: auto;
  // background-color: #fff;
  width: 100%;
  border-radius: 5px;
  display: flex;
  font-family: "Barlow", serif;
  font-size: 15px;

  textarea {
    resize: none;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #e2e2e2;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #e2e2e2;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #6588ff;
  }

  .contact-sidebar-wrapper {
    background: #fff;
    height: 100%;
    border-radius: 5px 0 0 5px;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 30px;
    box-shadow: -6px 1px 12px 0px rgba(34, 60, 80, 0.2);

    .logo-heading {
      padding: 13px 20px 13px 20px;
      display: flex;
      align-items: center;
      background-color: #eeeeee;
      border-radius: 22px 0 0 22px;

      img {
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 15px;

        @include avatar-shadow();
      }
      .user-name {
        color: #281f3f;
        font-size: 14px;
        font-weight: 500;

        @include three-dots-text();

        .title {
          // color: #fff;
          font-size: 14px;
          // background-color: #4c90e6;
          border-radius: 10px;
          width: fit-content;
          font-weight: 300;
          // padding: 1px 10px;
        }
      }
    }
    .contact-search {
      padding: 15px 20px 15px 20px;
      color: #fff;
      border-right: 3px solid #d6d5da;

      .search {
        background: #aec1ff;
        color: #fff;

        &::placeholder {
          /* Most modern browsers support this now. */
          color: #fff;
        }
      }
    }

    .contact-list {
      overflow-y: auto;
      flex: 1;
      // padding: 8px;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        .contact-element {
          padding: 10px 15px;
          transition: all 150ms ease-in;
          cursor: pointer;
          display: flex;
          align-items: center;
          background-color: #f8f8f8;
          border-bottom: 1px solid #e9e9eb;
          border-left: 5px solid transparent;
          font-size: 15px;

          @include opacity-animation();

          &:last-child {
            // border-radius: 0 0 0 10px;
          }

          &:hover {
            background-color: #f4f4f5;
          }
          &.active {
            // background-color: rgb(76, 144, 230);
            border-left: 5px solid #6588ff;
            .user {
              // color: #fff;
            }
            .unread-badge {
              // background-color: #fff;
              // color: #5380ff;
              font-weight: 500;
            }
          }
          .user {
            color: #281f3f;
            font-weight: 500;
          }

          .driver-avatar {
            width: 35px;
            height: 35px;
            margin-right: 15px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #591a39;
            color: #fff;
            font-weight: bold;
            @include avatar-shadow();

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .unread-badge {
            @include unread-badge();
          }
        }
      }
    }
  }

  .chat-screen-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 6px 3px 12px 0px rgba(34, 60, 80, 0.2);
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }

    .modal.fade {
      transition: opacity 0s ease;
    }

    .modal.fade .modal-dialog {
      transition: transform 0s ease;
    }

    .modal.fade.show {
      transition: opacity 150ms linear;
    }

    .chat-header {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 15px 20px 15px 20px;
      margin-left: -3px;
      border-left: 3px solid #d6d5da;
      background-color: #eeeeee;
      border-radius: 0 22px 0 0;

      &:after {
        display: none;
        position: absolute;
        content: "";
        width: 95%;
        height: 2px;
        background-color: #eff2f7;
        left: 2.5%;
        top: 75px;
      }

      .search {
        background-color: #f8f8f8;
      }

      .left {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 55px;
          height: 55px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;

          @include avatar-shadow();
        }

        .load-num {
          width: fit-content;
          color: #000;
          border-radius: 5px;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 5px;

          &.disabled {
            color: #b3acb4;
          }
        }

        .group-members {
          button {
            width: 100%;
            border-radius: 5px;
            color: #717579;
            padding: 0;
            background-color: transparent;
            display: flex;
            align-items: center;

            &.disabled {
              color: #b3acb4;
            }

            svg {
              margin-right: 5px;
              font-size: 14px;
            }
          }
        }
      }

      .right {
        flex-basis: 42%;
      }
      .chat-message-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: auto;
        top: 80px;
        z-index: 4;
        left: 0;
        // background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .all-members {
      .member {
        padding: 15px 10px;
        font-weight: 500;
        transition: all 250ms ease;
        color: #000;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background-color: #f4f4f5;
        }

        .last-seen {
          color: #999999;
          font-weight: 400;
        }

        .u-title {
          padding: 5px 12px;
          color: #fff;
          min-width: 70px;
          text-align: center;
          border-radius: 10px;
        }

        .Driver {
          background-color: #ff7a45;
        }
        .Admin {
          background-color: #034694;
        }
        .Dispatcher {
          background-color: #9254de;
        }
        .Safety {
          background-color: #73d13d;
        }
        .Update {
          background-color: #f7c600;
        }
      }
    }

    .chat-body {
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      flex: 1;
      padding: 10px 20px;
      overflow-y: auto;
      position: relative;
      background: rgb(255, 255, 255);
      // background: rgb(133, 163, 123);
      // background: radial-gradient(
      //   circle,
      //   rgba(133, 163, 123, 1) 0%,
      //   rgba(232, 220, 152, 1) 100%
      // );

      .message {
        width: fit-content;
        padding-bottom: 10px;
        border-radius: 15px;
        margin: 10px;
        min-width: 60px;
        max-width: 330px;
        position: relative;
        font-weight: 400;
        font-size: 17px;
        // font-family: "Roboto", Helvetica, sans-serif;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 12px;

        &.sender {
          margin-left: auto;
          background-color: #5e6ecf;
          text-align: left;
          color: #fff;

          .text {
            padding: 8px 8px 0 15px;
          }

          .user-info {
            display: none;
          }

          &:after {
            right: 11px;
            border-left-color: #5e6ecf;
            border-right: 0;
            margin-right: -20px;
            top: 100%;
            transform: translateY(-50%);
          }

          .audio-preview-wrap {
            &:after,
            &:before {
              content: "";
              right: 2px;
              top: 9px;
              width: 13px;
              height: 34px;
              position: absolute;
              background-color: #5e6ecf;
              z-index: 1;
            }
            &:before {
              right: auto;
              left: 2px;
            }
          }

          audio::-webkit-media-controls-panel {
            background-color: #a19130;
            color: #5e6ecf;
            border-radius: 0;
            filter: invert(1);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          // audio::-webkit-media-controls-play-button,
          // audio::-webkit-media-controls-mute-button {
          //   background-color: #9F8F2D;
          //   color: #fff;
          //   border-radius: 50%;
          // }

          .check-mark {
            color: #fff;
          }
        }

        &.receiver {
          background-color: #e2e2e2;
          text-align: left;

          &:after {
            left: 10px;
            border-right-color: #e2e2e2;
            border-left: 0;
            margin-left: -20px;
            top: 100%;
            transform: translateY(-50%);
          }

          .user-info {
            background-color: #e2e2e2;
            color: #ff7a45;
            font-weight: 500;
          }

          audio::-webkit-media-controls-play-button,
          audio::-webkit-media-controls-panel {
            background-color: #e2e2e2;
            color: #e2e2e2;
          }
          .timestamp {
            color: #3e4b65;
          }
        }

        .user-info {
          background-color: #73d13d;
          border-radius: 15px 15px 0 0;
          padding: 5px 12px 5px 12px;
          color: #fff;
          font-family: "Barlow", sans-serif;
          font-size: 14px;

          &.Admin {
          }
          &.Dispatcher {
            color: #40a9ff;
          }
        }
        .text {
          padding: 5px 8px 0 12px;
          display: flex;
          font-size: 15px;
          align-items: flex-end;
          justify-content: space-between;

          &.no-text {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 2px 6px;
            border-radius: 5px;

            .timestamp {
              margin: 1px 0 0 0;
              color: #fff;
              .check-mark {
                color: #fff;
              }
            }
          }

          .main-txt {
            flex: 1;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          width: 0;
          height: 0;
          border: 20px solid transparent;
          border-bottom: 0;
          margin-top: -10px;
        }

        &.pb-0 {
          &:after {
            display: none;
          }
        }

        .timestamp {
          color: #fff;
          font-size: 12px;
          position: relative;
          bottom: 0px;
          text-align: right;
          right: 0;
          margin-left: 10px;
          margin-top: 5px;
          display: flex;

          .check-mark {
            font-size: 14px;
            margin-left: 5px;
            display: flex;
          }
        }

        .preview-wrap {
          // padding-bottom: 100%;
          // width: 250px;
          max-width: 320px;
          // height: 0;
          position: relative;

          .video-preview {
            width: 100%;
            height: 100%;
            display: block;
            // padding: 10px 10px 0 10px;
            object-fit: cover;

            @include no-user-drag();
          }

          &.img {
            .image-info {
              padding: 10px 10px 0 10px;
              display: flex;
              align-items: center;

              .details {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                font-weight: 500;

                span {
                  max-width: 220px;
                  @include three-dots-text();

                  &:last-child {
                    color: #4fae4e;
                  }
                }
              }
              display: flex;
            }
            .img-preview {
              position: relative;
              transition: all 250ms ease;
              img {
                width: 70px;
                height: 70px;
                object-fit: cover;
                border-radius: 5px;
              }
              &.full {
                img {
                  width: 100%;
                  height: 100%;
                  min-height: 70px;
                }
              }
            }
          }
          &.img,
          &.video {
            transition: all 250ms ease;

            .overlay {
              display: none;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              // height: calc(100% + 38px);
              height: 100%;
              width: 100%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: rgba(0, 0, 0, 0.3);
              z-index: 2;
              opacity: 0;
              font-size: 14px;
              color: #fff;
              border-radius: 5px;
              cursor: pointer;

              @include no-user-drag();

              svg {
                font-size: 25px;
              }
            }

            &.video {
              .overlay {
                display: flex;
                opacity: 1;

                .svg-play {
                  width: 50px;
                  height: 50px;
                  background: rgba(255, 255, 255, 0.4);
                  color: #fff;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    font-size: 30px;
                    margin-left: 2px;
                  }
                }
              }
            }

            &:hover {
              transition: display, opacity 250ms ease;
              .overlay {
                transition: display, opacity 250ms ease;
                display: flex;
                opacity: 1;
              }
            }
          }
        }

        .audio-preview-wrap {
          position: relative;
        }

        .audio-preview {
          margin-top: 10px;
          background-color: transparent;
          max-height: 32px;
          border-radius: 10px;
          padding: 0 2px;
        }

        .file-preview-wrap {
          padding: 10px 10px 0 10px;
          min-width: 180px;
          display: flex;
          align-items: center;

          * {
            @include no-user-drag();
          }

          a {
            word-break: break-all;
            flex-basis: 88%;
          }

          svg {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            // flex-basis: 10%;
          }
        }
      }

      .sent-date {
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        border-radius: 10px;
        width: fit-content;
        margin: 0 auto;
        padding: 0 8px 0 8px;
        // font-family: "Roboto", Helvetica, Arial, sans-serif;
        font-size: 14px;
      }

      .endOfMessage {
        overflow-x: hidden;
      }

      .modal-preview-wrap {
        @import "custom_coreUI_modal.scss";

        .file-modal-preview {
          width: fit-content;
          height: 100%;
          margin: 0 auto;

          img,
          video {
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            @include no-user-drag();
          }
        }
      }
      .modal-backdrop {
        opacity: 0.7;
        background-color: #000;
      }
    }

    .chat-footer {
      border-top: 1px solid #dadce0;
      border-left: 1px solid #dadce0;
      border-right: 1px solid #dadce0;
      border-bottom-right-radius: 22px;
      padding: 0;
      background-color: #e2e2e2;

      &:before {
        display: none;
        position: absolute;
        content: "";
        width: 95%;
        height: 2px;
        background-color: #eff2f7;
        left: 2.5%;
        margin-bottom: 10px;
      }

      form {
        position: relative;
        padding: 5px 0 8px 0;

        .search {
          padding: 35px 110px 0 65px;
          height: 45px;
          position: relative;
          background-color: transparent;
          margin-top: -15px;
          font-size: 15px;
          overflow: hidden;
        }

        .file-upload {
          position: absolute;
          top: 50%;
          transform: translateY(-20px);
          left: 0;
          font-size: 14px;
          z-index: 1;
          background: #cdcdcd;
          border-radius: 50%;
          margin: 0 0 0 10px;

          label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 41px;
            cursor: pointer;
            margin-bottom: 0;

            svg path {
              stroke: #6588ff;
            }
          }
          &:hover {
            label {
              svg path {
                stroke: #7c7c7c;
              }
            }
          }
        }

        .file-drop-modal {
          @import "custom_coreUI_modal.scss";

          .modal-content {
            justify-content: center;
          }
        }

        .file-drop-zone {
          .drop-here {
            width: 800px;
            height: 600px;
            border: 2px dashed #fff;
            font-size: 14px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.1);
            margin-bottom: 7%;
          }

          .dropped-files {
            width: fit-content;
            max-width: 500px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 25px;
            background-color: #fff;
            border-radius: 5px;

            img,
            video {
              display: block;
              margin: 0 auto;
              max-width: 100%;
              height: auto;
              max-height: 600px;
              object-fit: cover;
              // margin: 0 10px;
            }

            .dropped-video {
              width: 100%;
              position: relative;

              svg {
                position: absolute;
                font-size: 45px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
              }
            }

            .caption {
              width: 100%;

              .search {
                padding: 10px 10px 5px 2px;
                margin-top: 25px;
                background-color: transparent;
                border-bottom: 2px solid #e0e0e0;
                border-radius: 0;

                &:focus {
                  border-color: #168acd;
                }
                &::placeholder {
                  color: #989798;
                  font-weight: 500;
                  font-size: 14px;
                }
              }
            }

            .dropped-file {
              svg {
                font-size: 35px;
                margin-right: 15px;
              }
            }
          }

          .action-btns {
            width: 100%;
            margin: 15px auto 0 auto;
            display: flex;
            justify-content: flex-end;

            button {
              padding: 5px 10px;
              font-size: 14px;
              text-transform: uppercase;
              margin-left: 20px;
              border-radius: 5px;
              background: none;
              font-weight: 500;
              color: #168acd;

              &:hover {
                background-color: #dbeefb;
              }
            }
          }
        }

        #file-input {
          display: none;
        }

        .send-icon {
          position: absolute;
          z-index: 1;
          top: 20%;
          right: 0;
          font-size: 14px;
          height: 41px;
          width: 41px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 50%;
          background-color: #cdcdcd;
          margin-right: 10px;

          svg path {
            fill: #5e6ecf;
          }

          &.emoji {
            width: 40px;
            right: 50px;
          }

          &.emoji-mic {
            width: 40px;
            right: 125px;
          }

          &.microphone {
          }

          &.trash {
            width: 40px;
            right: 40px;

            svg {
              fill: #e53935;
            }
          }

          &.mic {
            width: 40px;
            border-radius: 50%;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
            transform: scale(1);
            animation: pulse-blue 1.5s infinite;
          }

          svg {
            fill: #3e4b65;
          }
        }

        .emoji-picker {
          position: absolute;
          z-index: 3;
          right: 0;
          bottom: 50px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          display: none;

          &.open {
            display: block;
          }
        }

        .audio-timer {
          position: absolute;
          z-index: 1;
          top: 22%;
          right: 93px;
          height: 40px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
      }

      .modal-backdrop {
        opacity: 0.7;
        background-color: #000;
      }

      .search {
        background-color: #f0f0f0;
        margin-top: 15px;
        padding: 10px 10px 10px 60px;

        &:focus {
          box-shadow: none;
        }
      }

      .scroll-to-bottom {
        position: absolute;
        bottom: 80px;
        right: 15px;
        // background-color: rgba(135, 135, 135, 0.8);
        background-color: #fff;
        z-index: 2;
        border-radius: 10em;
        color: #a8a8a8;
        font-size: 14px;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        cursor: pointer;
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        // opacity: 0.5;
        opacity: 1;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        @include no-user-drag();

        .active {
          right: 25px;
        }

        &:hover {
          // background-color: #4c7dfe;
          background-color: #eeeeee;
          opacity: 1;
        }

        .unread-count {
          width: 20px;
          height: 20px;
          position: absolute;
          top: -7px;
          right: -6px;
          font-size: 12px;
          border-radius: 50%;
          background: #215efe;
          line-height: 20px;
        }
      }
    }
  }

  .loads-sidebar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f9f9f9;
    margin-left: 15px;
    border-radius: 22px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .driver-info {
      position: relative;
      padding: 20px;
    }

    .chat-search-wrap {
      margin: 0 20px 20px 20px;
    }

    .loads {
      height: 100%;
      overflow-y: auto;
      padding: 8px;

      .load {
        padding: 10px 15px;
        transition: all 150ms ease-in;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        border-radius: 5px;

        // @include btn-hover-effect();

        &:hover {
          background-color: #f4f4f5;
        }
        &.active {
          background-color: #5691e9;
          color: #fff;

          .load-info {
            color: #fff;

            .check-mark {
              color: #fff;
            }

            .current-load {
              color: #fff;
            }
            .last-message {
              color: #fff;
              .msg-owner {
                color: #fff;
              }
            }
          }
        }

        .load-info {
          color: #597bdd;
          font-weight: 500;
          flex: 1;
          overflow: hidden;
          font-size: 14px;

          .current-load {
            color: #73cc69;
          }

          .last-message {
            @include three-dots-text();

            color: #717579;
            font-weight: 400;
            margin-top: 5px;

            .msg-owner {
              color: #000;
            }
          }
        }
        .badges {
          .time {
            font-size: 15px;
            text-align: right;
            margin-bottom: 10px;
          }
          .unread-badge {
            @include unread-badge();
          }
        }
      }
    }
  }

  .chat-search-wrap {
    position: relative;
    .s-icon {
      width: 1.2rem !important;
      height: 1.2rem !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }
  }

  /* Reusable Chat elements */
  .go-back-btn {
    width: 40px;
    height: 40px;
    font-size: 14px;
    background-color: #eee;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
  }
  .check-mark {
    display: inline-block;
    margin-left: 10px;
    text-align: left;
    font-size: 14px;
    color: #6589ff;
    svg {
      margin-bottom: 2px;
    }
  }
  .search {
    background: #fff;
    border: none;
    padding: 7px 7px 7px 15px;
    width: 100%;
    border-radius: 15px;
    transition: box-shadow 250ms ease;
    font-size: 14px;

    &:focus {
      outline: none;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
  button {
    border: none;

    &:focus {
      outline: none;
    }
  }
}
